<template>
  <div class="animated fadeIn container">
    <b-row class="justify-content-center" style="max-width: 680px; margin: 0 auto">
      <b-col>
        <b-card-group>
          <b-card no-body class="p-4 mb-0">
            <b-card-body>
              <b-form>
                <h1>登录</h1>
                <b-input-group class="mb-3 mt-3">
                  <b-input-group-prepend>
                    <b-input-group-text>@</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input class="form-control" placeholder="账号" v-model="email" />
                </b-input-group>
                <b-input-group class="mb-4">
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="icon-lock"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="password"
                    class="form-control"
                    placeholder="密码"
                    autocomplete="current-password"
                    v-model="password"
                  />
                </b-input-group>
                <b-row>
                  <b-col cols="12">
                    <b-button variant="primary" class="px-4 login-btn" @click.prevent="login">登录</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card no-body class="text-white py-5 signup-card" style="background: #f7918d">
            <b-card-body class="text-center">
              <div style="position: absolute; top: 50%; left: 0; width: 100%; transform: translateY(-50%)">
                <p style="margin: 0; font-size: 28px; line-height: 28px">国际信息平台</p>
                <p style="margin: 10px 0 0; font-size: 38px; font-weight: 700; line-height: 38px">红少年短信</p>
                <b-button variant="primary" class="active mt-3" @click.prevent="$router.push('/register')"
                  >注册会员</b-button
                >
              </div>
            </b-card-body>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <b-modal ref="otpModal" title="OTP Verification" centered hide-footer style="width: 300">
      <b-form-group class="mt-2">
        <input type="text" class="form-control" placeholder="Enter OTP Number" v-model="otpNumber" />
      </b-form-group>
      <b-btn class="mt-3" variant="primary" @click="verifyOTP" block>Confirm</b-btn>
    </b-modal>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  created() {
    if (this.user) {
      this.$router.push('/smsSend')
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.remove('bg-white')
  },
  data() {
    return {
      email: '',
      password: '',
      otpNumber: '',
      error: null,
    }
  },
  methods: {
    async login() {
      try {
        this.showSpinner()
        const response = await AuthService.login({
          email: this.email,
          password: this.password,
        })

        const { token, user, useOTP = 0 } = response.data

        if (useOTP) {
          this.$refs['otpModal'].show()
          return
        }

        this.hideSpinner()
        this.loginSuccess(token, user)
      } catch (err) {
        this.hideSpinner()
        this.error = err.response.data.error
      }
    },
    async loginSuccess(token, user) {
      this.$cookie.set('redboysms_token', token, { expires: '1Y' })
      this.$store.dispatch('setToken', token)
      this.$store.dispatch('setUser', user)
      this.$router.push('/smsSend')
    },
    async verifyOTP() {
      try {
        const response = await AuthService.verifyOTP(this.email, this.otpNumber)
        if (response.status != 200) {
          return
        }
        const { token, user } = response.data

        this.loginSuccess(token, user)
      } catch (e) {}
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>
